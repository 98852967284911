
import {defineComponent, ref, onUnmounted, nextTick, watch} from "vue";
import {getSchoolIcon} from "@/api/webIndex";

export default defineComponent({
  name: 'LogoBanner',
  props: {
    speed: {
      default: 1,
      type: Number
    },
    isShadow: {
      default: true,
      type: Boolean
    },
    transformHeight: {
      default: 90,
      type: Number
    },
    isRotate: {
      default: false,
      type: Boolean
    },
    page: {
      default: () => 1,
      type: Number
    },
    size: {
      default: () => 6,
      type: Number
    },
  },
  setup(props) {
    const defaultLogoList = [
      {
        id: 0,
        icon: require('@/assets/dbsf.png')
      },
      {
        id: 1,
        icon: require('@/assets/gxmz.png')
      },
      {
        id: 2,
        icon: require('@/assets/gxnn.png')
      },
      {
        id: 3,
        icon: require('@/assets/gxsf.png')
      },
      {
        id: 4,
        icon: require('@/assets/gyxy.png')
      },
      {
        id: 5,
        icon: require('@/assets/gjkf.png')
      }
    ]

    const
        logoLeft = ref<number>(0),
        interTime = ref<any>(0),
        contentWrapWidth = ref<number>(0),
        contentWrap = ref<any>(),
        logoList = ref<any>([])

    const onStart = () => {
          contentWrapWidth.value = !props.isRotate ? ((contentWrap.value?.clientWidth) / 2) : ((contentWrap.value?.clientHeight) / 2)
          interTime.value = setInterval(() => {
            logoLeft.value -= props.speed
            logoLeft.value <= -contentWrapWidth.value ? logoLeft.value = 0 : ''
          }, 16.7)
        },
        onGetSchoolIcon = async () => {
          try {
            let res = await getSchoolIcon({page: props.page, per_page: props.size})
            if (res.message === 'ok') {
              logoList.value = [...res.data.data, ...res.data.data]
            } else {
              logoList.value = [...defaultLogoList, ...defaultLogoList]
            }
            await nextTick(() => {
              onStart()
            })
          } catch (e) {
            console.log(e)
            logoList.value = [...defaultLogoList, ...defaultLogoList]
            await nextTick(() => {
              onStart()
            })
          }
        }

    const watchFun = watch(props, () => {
      props.size !== 0 && onGetSchoolIcon()
    }, {
      immediate: true,
      deep: true
    })

    // onMounted(() => {
    //   onGetSchoolIcon()
    // })

    onUnmounted(() => {
      clearInterval(interTime.value)
    })

    return {
      logoLeft,
      interTime,
      contentWrapWidth,
      contentWrap,
      logoList,
      onStart,
      onGetSchoolIcon,
      watchFun
    }
  }
})
