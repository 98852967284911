import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-029596bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["client-wrap relative", _ctx.isRotate?'w-300 h-700':'w-1200 h-90'])
  }, [
    _createElementVNode("div", {
      ref: "contentWrap",
      class: _normalizeClass(["content-wrap absolute l-0", _ctx.isRotate?'flex-col w-200':'h-60 flex-row flex-no-wrap']),
      style: _normalizeStyle({transform:_ctx.isRotate?`translateY(${_ctx.logoLeft}px)`:`translateX(${_ctx.logoLeft}px)`})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logoList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["logo-list w-200 h-60 brs12 text-center", {shadow:_ctx.isShadow,'m-r-30':!_ctx.isRotate,'m-t-30':_ctx.isRotate}]),
          key: item.id
        }, [
          _createElementVNode("img", {
            src: item.icon,
            alt: "",
            class: "w-200 h-60"
          }, null, 8, _hoisted_1)
        ], 2))
      }), 128))
    ], 6)
  ], 2))
}